import React from 'react';
import TermsConditionsPage from 'ui/content/terms-condition';
import Layout from '../components/layout';
import Seo from '../components/seo';

const TermsOfUse = () => (
  <Layout showFooter={false}>
    <Seo title="Terms of Service" />
    <TermsConditionsPage externalRender />
  </Layout>
);

export default TermsOfUse;
